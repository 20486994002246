import React, { useContext } from 'react';
import Head from 'next/head';
import { SiteContext } from '../../pages/_app';
import { configuration } from '../../utils/environment';
import { CmsAsset } from '../../integrations/contentful/types';
import { isImageContent } from '../../integrations/contentful/utils';

export enum OGType {
  article = 'article',
  website = 'website',
  profile = 'profile'
}

interface SEOProps {
  title: string;
  description: string;
  canonicalPath: string;
  mainImage?: CmsAsset;
  type?: OGType;
  noindex?: boolean;
}

const SEO: React.FunctionComponent<SEOProps> = ({
  title,
  description,
  canonicalPath,
  mainImage,
  type,
  noindex
}) => {
  const { urlGenerator } = useContext(SiteContext);
  const canonicalUrl = urlGenerator.generateUrl(canonicalPath);
  return (
    <Head>
      <title>{title + configuration.siteTitleSuffix}</title>
      <meta property="og:title" content={title} />

      <meta property="og:url" content={canonicalUrl} />
      <link rel="canonical" href={canonicalUrl} />

      <meta property="og:description" content={description} />
      <meta name="description" content={description} />

      {noindex && <meta name="robots" content="noindex" />}
      <meta property="og:type" content={type || OGType.article} />

      {mainImage && isImageContent(mainImage.fields.file.contentType) && (
        <>
          {/* It would be nice to have Contentful image url with the certain size because some original images might be oversize to crawlers.
          Unfortunately we cannot here generate working Contenful url to certain image sizes due this issue:
          https://github.com/facebook/react/issues/13838 (head > meta > content escaping issue)

          Issue in next.js project:
          https://github.com/zeit/next.js/issues/2006 (Escaping problem with meta tags) */}
          <meta property="og:image" content={mainImage.fields.file.url} />
          <meta
            property="og:image:width"
            content={`${
              mainImage.fields.file.details.image &&
              mainImage.fields.file.details.image.width
            }`}
          />
          <meta
            property="og:image:height"
            content={`${
              mainImage.fields.file.details.image &&
              mainImage.fields.file.details.image.height
            }`}
          />
        </>
      )}

      <meta property="og:locale" content="fi_FI" />
    </Head>
  );
};

export default SEO;
