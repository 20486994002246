import React from 'react';
import classNames from 'classnames';
import {
  baseXInPixels,
  baseXUnit,
  breakpoints,
  cardImageMaxWidthInPixels,
  spacing
} from '../../styles/theme';

export enum VisibleCards {
  One = '85vw',
  Two = '38vw'
}

interface HorizontalCardListProps {
  visibleCards: VisibleCards;
  expand?: boolean;
}

/**
 * @param visibleCards How many cards are visible on the screen at once.
 * @param [expand] If true, draws list over one container padding so that it reaches screen's edges.
 * @param children
 * @param rest
 * @constructor
 */
const HorizontalCardList: React.FunctionComponent<
  HorizontalCardListProps & React.HTMLAttributes<HTMLElement>
> = ({ children, visibleCards, expand, ...rest }) => {
  return (
    <div className={classNames('horizontal-card-list', { expand })} {...rest}>
      {React.Children.map(children, (child, index) => (
        <div className="child" key={index}>
          {child}
        </div>
      ))}
      {/*language=CSS*/}
      <style jsx>{`
        .horizontal-card-list {
          display: flex;
          flex-wrap: nowrap;
          overflow-x: auto;
          overflow-y: hidden;
          -ms-overflow-style: none; // IE 10+
          scrollbar-width: none; // Firefox
        }

        .horizontal-card-list.expand {
          /* On small devices draw the list over one container padding so that it reaches from screen's edge to edge
             Otherwise we would need to split the markup everywhere to separate Containers where we need horizontal lists.
             While often possible, it gets irksome with responsive columns.
          */
          margin-left: -${spacing.two};
          margin-right: -${spacing.two};
        }

        @media ${breakpoints.large} {
          .horizontal-card-list {
            margin-left: 0;
            margin-right: 0;
          }
        }

        .horizontal-card-list::-webkit-scrollbar {
          display: none; // Safari and Chrome
        }

        .child {
          position: relative;
          display: inline-block;
          margin: 0 ${baseXUnit(1)};
        }

        .child:first-child {
          margin-left: ${baseXUnit(2)};
        }

        /* Add right "margin" to the end of the list since the 
           browser won’t add right padding when you scroll to the end.
           Read more:
           https://blog.alexandergottlieb.com/overflow-scroll-and-the-right-padding-problem-a-css-only-solution-6d442915b3f4
         */
        .child:last-child:after {
          content: '';
          display: block;
          position: absolute;
          right: -${baseXUnit(2)};
          width: ${baseXUnit(2)};
          height: 1px;
        }
      `}</style>
      {/*language=CSS*/}
      <style jsx>{`
        .child {
          max-width: ${visibleCards};
          min-width: ${visibleCards};
        }

        @media screen and (min-width: ${cardImageMaxWidthInPixels +
          baseXInPixels(6)}px) {
          .child {
            max-width: ${cardImageMaxWidthInPixels}px;
          }
        }
      `}</style>
    </div>
  );
};

export default HorizontalCardList;
