import React, { useContext } from 'react';
import Head from 'next/head';
import { SiteContext } from '../../pages/_app';
import { UrlGenerator } from '../../urls/UrlGenerator';
import { t } from '../../utils/i18n';
import { Paths } from '../../urls/Paths';

export interface BreadcrumbItem {
  name: string;
  path: string;
}
interface BreadCrumbsProps {
  items?: BreadcrumbItem[];
}

export function generateBreadcrumbList(
  items: BreadcrumbItem[],
  urlGenerator: UrlGenerator
) {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: items.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': urlGenerator.generateUrl(item.path),
        name: item.name
      }
    }))
  };
}

const homePageItem = {
  // TODO Check from search results, if this should be branded as "Tim" rather than "Etusivu".
  name: t('Etusivu'),
  path: Paths.Home
};

const Breadcrumbs: React.FunctionComponent<BreadCrumbsProps> = ({
  items = []
}) => {
  const { urlGenerator } = useContext(SiteContext);
  const breadcrumbList = generateBreadcrumbList(
    [homePageItem, ...items],
    urlGenerator
  );
  return (
    <Head>
      <script type="application/ld+json" data-cy="breadcrumbs">
        {JSON.stringify(breadcrumbList)}
      </script>
    </Head>
  );
};

export default Breadcrumbs;
