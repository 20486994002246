import React from 'react';
import classNames from 'classnames';
import { baseXUnit, color } from '../../styles/theme';

type SpacingSize = 'small' | 'large';

interface ButtonProps {
  tag?: 'div' | 'button';
  spacing?: SpacingSize;
  selected?: boolean;
}

const Button: React.FunctionComponent<
  ButtonProps & React.HTMLAttributes<HTMLElement>
> = ({ spacing, children, selected, tag, ...rest }) => {
  rest.className = classNames(
    'button',
    { small: spacing === 'small', selected: selected },
    rest.className
  );
  const Tag = tag || 'button';
  return (
    <Tag {...rest}>
      {children}
      {/*language=CSS*/}
      <style jsx>{`
        .button {
          cursor: pointer;
          border-radius: ${baseXUnit(1)};
          background: ${color.purpleLight};
          border: 1px solid transparent;
          padding: ${baseXUnit(1)} ${baseXUnit(2)};
          box-shadow: 0 ${baseXUnit(0.2)} ${baseXUnit(2)} 0
            rgba(124, 34, 195, 0.4);
          transition: 0.5s;
        }
        .button:hover {
          background: ${color.purple};
          box-shadow: 0 ${baseXUnit(0.2)} ${baseXUnit(2)} 0
            rgba(124, 34, 195, 0.6);
        }
        .button:active {
          background: ${color.pool};
          box-shadow: none;
        }
        div.button {
          display: inline-block;
        }
        .button.small {
          padding: ${baseXUnit(1)};
        }
        .button.selected {
          border-radius: ${baseXUnit(1)} ${baseXUnit(1)} 0 ${baseXUnit(1)};
          background: ${color.purple};
        }
      `}</style>
    </Tag>
  );
};

export default Button;
