import React from 'react';
import Icon from '../common/Icon';
import DivButton from '../a11y/DivButton';
import { t } from '../../utils/i18n';
import Navbar from '../navigation/Navbar';
import H from '../elements/H';
import { baseXUnit, color, zIndex } from '../../styles/theme';

export interface ModalNavbarProps {
  closeDialog: () => void;
  title: string;
}

const closeIconSize = 4;
const ModalNavbar: React.FunctionComponent<ModalNavbarProps> = ({
  closeDialog,
  title
}) => {
  return (
    <div className="modal-nav-bar">
      <Navbar>
        <div className="left-icon-spacer" />
        <div
          className="modal-title"
          data-testid="modal-title"
          data-cy="modal-title"
        >
          <H styling="h5">{title}</H>
        </div>
        <div>
          <DivButton
            onClick={closeDialog}
            data-testid="close-modal"
            data-cy="close-modal"
          >
            <Icon
              name="nav-close"
              size={closeIconSize}
              screenReaderLabel={t('Sulje näkymä')}
            />
          </DivButton>
        </div>
      </Navbar>
      {/*language=CSS*/}
      <style jsx>{`
        .left-icon-spacer {
          visibility: hidden;
          width: ${baseXUnit(closeIconSize)};
        }
        .modal-title {
          align-self: center;
        }
        .modal-nav-bar {
          z-index: ${zIndex.navBar};
          width: 100%;
          position: sticky;
          top: 0;
          background-image: linear-gradient(
            to bottom,
            ${color.white},
            rgba(255, 255, 255, 0)
          );
        }
      `}</style>
    </div>
  );
};

export default ModalNavbar;
