import React from 'react';
import { CmsAsset } from '../../integrations/contentful/types';
import H from '../elements/H';
import { baseXUnit, imageHoverOpacity } from '../../styles/theme';
import Spacing from '../common/Spacing';
import RouteLink from '../navigation/RouteLink';
import { Paths } from '../../urls/Paths';
import Paragraph from '../elements/Paragraph';
import CmsMediaFixedHeight from '../cms/CmsMediaFixedHeight';
import HyperLink from '../elements/HyperLink';

interface DestinationCardProps {
  country: string;
  mainImage: CmsAsset;
  name: string;
  slug: string;
}

const DestinationCard: React.FunctionComponent<DestinationCardProps> = ({
  country,
  mainImage,
  name,
  slug
}) => {
  return (
    <div className="destination-card" data-cy="destination-card">
      <RouteLink
        path={Paths.Destination}
        params={{
          slug: slug
        }}
      >
        <HyperLink noLinkStateStyles data-testid="destination-link">
          <Spacing bottom={1}>
            <CmsMediaFixedHeight
              media={mainImage}
              fixedHeight={18}
              borderRadius={1}
            />
          </Spacing>
          <Spacing bottom={2}>
            <H tag="h3" styling="h3">
              <strong>{name}</strong>
            </H>
            <Paragraph data-testid="country">{country}</Paragraph>
          </Spacing>
        </HyperLink>
      </RouteLink>
      {/*language=CSS*/}
      <style jsx>{`
        .destination-card {
          padding-bottom: ${baseXUnit(2)};
        }
        .destination-card:hover :global(img) {
          opacity: ${imageHoverOpacity};
        }
      `}</style>
    </div>
  );
};

export default DestinationCard;
