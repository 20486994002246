import Typist, { TypistProps } from 'react-typist';
import React from 'react';
import { configuration } from '../../utils/environment';

interface TestableTypistProps extends TypistProps {
  disableAnimation?: boolean;
}

export const TestableTypist: React.FunctionComponent<TestableTypistProps> = ({
  disableAnimation,
  ...typistProps
}) => {
  if (configuration.disableAnimations || disableAnimation) {
    typistProps = {
      ...typistProps,
      avgTypingDelay: 0,
      stdTypingDelay: 0,
      startDelay: 0
    };
  }
  return <Typist {...typistProps} />;
};
