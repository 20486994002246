import React from 'react';
import { TestableTypist } from '../module-overrides/typist';

interface TypedTextProps {
  onAnimationDone?: () => void;
  startDelay?: number;
  disableAnimation?: boolean;
  wait?: boolean;
}

const TypedText: React.FunctionComponent<TypedTextProps> = ({
  children,
  onAnimationDone,
  startDelay,
  disableAnimation,
  wait
}) => {
  return (
    <div className="typed-text">
      <div className="hidden-text" data-testid="hidden-text">
        {children}
      </div>
      <div className="visible-text" data-testid="visible-text">
        {!wait && (
          <TestableTypist
            onTypingDone={onAnimationDone}
            avgTypingDelay={25}
            stdTypingDelay={5}
            startDelay={startDelay}
            disableAnimation={disableAnimation}
          >
            {children}
          </TestableTypist>
        )}
      </div>
      {/*language=CSS*/}
      <style jsx>{`
        .typed-text {
          position: relative;
        }
        .hidden-text {
          opacity: 0;
        }
        .visible-text {
          position: absolute;
          top: 0;
          left: 0;
        }
      `}</style>
    </div>
  );
};

export default TypedText;
