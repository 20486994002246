import React from 'react';
import lottie, {
  AnimationDirection,
  AnimationItem,
  AnimationSegment
} from 'lottie-web';
import isEqual from 'lodash/isEqual';

export interface OnSegmentStartEvent {
  type: 'segmentStart';
  firstFrame: number;
  totalFrames: number;
}

export interface LottieProps {
  // NOTE: If loop is true and segments are given,
  // only the last segment will loop.
  loop?: boolean;
  autoplay?: boolean;
  animationPath: string;
  isStopped?: boolean;
  isPaused?: boolean;
  segments?: AnimationSegment | AnimationSegment[];
  speed?: number;
  direction?: AnimationDirection;
  ariaRole?: string;
  ariaLabel?: string;
  onComplete?: () => void;
  onSegmentStart?: (event: OnSegmentStartEvent) => void;
}

interface LottieState {
  animationLoaded: boolean;
}

export default class Lottie extends React.Component<LottieProps, LottieState> {
  public state = {
    animationLoaded: false
  };
  private containerRef = React.createRef<HTMLDivElement>();
  private anim: AnimationItem | null = null;

  public componentDidMount() {
    this.loadAnimation();
  }

  public loadAnimation() {
    if (!this.containerRef.current) {
      return;
    }

    const { loop, autoplay, animationPath, onComplete, onSegmentStart } =
      this.props;

    this.anim = lottie.loadAnimation({
      container: this.containerRef.current,
      renderer: 'svg',
      loop,
      autoplay,
      path: animationPath
    });
    if (onComplete) {
      this.anim.addEventListener('complete', onComplete);
    }

    if (onSegmentStart) {
      this.anim.addEventListener('segmentStart', onSegmentStart);
    }
    this.anim.addEventListener('DOMLoaded', () => {
      this.setState({ animationLoaded: true });
    });
  }

  public componentDidUpdate(prevProps: LottieProps) {
    if (this.props.animationPath !== prevProps.animationPath) {
      this.loadAnimation();
    }

    const segmentsChanged = !isEqual(this.props.segments, prevProps.segments);

    this.setPlaySettings(segmentsChanged);
  }

  public componentWillUnmount() {
    this.destroy();
    this.anim = null;
  }

  public setPlaySettings(segmentsChanged: boolean) {
    if (this.props.isStopped) {
      this.stop();
    } else if (this.props.isPaused) {
      this.pause();
    } else if (this.props.segments) {
      this.playSegments(segmentsChanged);
    } else {
      this.play();
    }

    this.setSpeed();
    this.setDirection();
  }

  public setSpeed() {
    this.anim && this.anim.setSpeed(this.props.speed || 1);
  }

  public setDirection() {
    this.anim && this.anim.setDirection(this.props.direction || 1);
  }

  public play() {
    this.anim && this.anim.play();
  }

  public playSegments(segmentsChanged: boolean) {
    if (!this.anim || !this.state.animationLoaded || !this.props.segments) {
      return;
    }
    const animationStarted =
      // @ts-ignore
      this.anim.currentFrame > 0;
    if (!animationStarted) {
      this.anim.playSegments(
        this.props.segments,
        // This needs to be set so that the last segment loops.
        true
      );
    } else if (segmentsChanged) {
      this.anim.playSegments(
        this.props.segments,
        // When force flag is set to false, the current segment is played to the end.
        false
      );
    } else {
      this.play();
    }
  }

  public stop() {
    this.anim && this.anim.stop();
  }

  public pause() {
    this.anim && this.anim.pause();
  }

  public destroy() {
    this.anim && this.anim.destroy();
  }

  public render() {
    const { ariaRole, ariaLabel } = this.props;

    return (
      <div
        ref={this.containerRef}
        role={ariaRole}
        aria-label={ariaLabel}
        className="lottie"
      >
        {/*language=CSS*/}
        <style jsx>{`
          .lottie :global(svg) {
            display: block;
          }
        `}</style>
      </div>
    );
  }
}
